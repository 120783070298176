import {
    SET_ALERT_INFO,
    CLEAR_ALERT_INFO,
    SET_IS_MOBILE,
    SET_IS_LOADING,
	SET_IS_EDIT_DISABLED,
} from './general.types';

export const setAlert = (alertInfo) => {
    return {
        type: SET_ALERT_INFO,
        alertInfo
    };
};

export const clearAlert = () => {
    return {
        type: CLEAR_ALERT_INFO
    };
};

export const setIsMobile = (isMobile) => {
    return {    
        type: SET_IS_MOBILE,
        isMobile
    }
}

export const setIsLoading = (isLoading) => {
    return {
        type: SET_IS_LOADING,
        isLoading
    }
}

export const setIsEditDisabled = (isDisabled) => {
    return {
        type: SET_IS_EDIT_DISABLED,
        isDisabled
    }
}




