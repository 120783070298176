import React, { useEffect, FC, SetStateAction, Dispatch } from 'react';
import './mobile-header.scss';
import hamburger from '../../../assets/icons/hamburger.svg';
import logoSmall from '../../../assets/logos/deploi-logo-sm.svg';
import { useSelector } from 'react-redux';
import MobileHeaderBuckets from '../mobile-header-buckets/mobile-header-buckets';
import { BucketData, BucketState } from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import { ILinkState } from '../../../types/interfaces/misc.interface';
import { useNavigate } from 'react-router-dom';

type Props = {
	handleLogout: (event: React.MouseEvent<HTMLDivElement>) => void;
	state: BucketState;
	handleSelectBucket: (bucket: BucketData) => void;
	handleAddStaff: (event: React.MouseEvent<HTMLDivElement>) => void;
	handleOpenOrganization: (event: React.MouseEvent<HTMLDivElement>) => void;
	isLoggedOut: boolean;
	setMobileBucketsOpen: Dispatch<SetStateAction<boolean>>;
	mobileBucketsOpen: boolean;
	selectedState: ILinkState;
};

const MobileHeader: FC<Props> = ({
	handleLogout,
	state,
	handleSelectBucket,
	handleAddStaff,
	isLoggedOut,
	mobileBucketsOpen,
	setMobileBucketsOpen,
	handleOpenOrganization,
	selectedState,
}) => {
	const user = useSelector((state: RootState) => state.auth.user);
	const navigate = useNavigate();

	useEffect(() => {
		if (isLoggedOut) {
			setMobileBucketsOpen(false);
		}
	}, [isLoggedOut, setMobileBucketsOpen]);

	return (
		<>
			<div className='mobile-header'>
				<div className='hamburger'>
					<img
						src={hamburger}
						alt='hamburger'
						onClick={() => setMobileBucketsOpen(!mobileBucketsOpen)}
					/>
				</div>
				<div>
					<img
						src={logoSmall}
						alt='logo'
						onClick={() => {
							return navigate('/check');
						}}
					/>
				</div>
				<div>
					{user && user.name && (
						<div className='avatar'>
							{user.name[0].toUpperCase()}
						</div>
					)}
				</div>
			</div>
			<div>
				{mobileBucketsOpen && (
					<MobileHeaderBuckets
						handleLogout={handleLogout}
						state={state}
						handleSelectBucket={handleSelectBucket}
						handleAddStaff={handleAddStaff}
						handleOpenOrganization={handleOpenOrganization}
						selectedState={selectedState}
					/>
				)}
			</div>
		</>
	);
};

export default MobileHeader;
