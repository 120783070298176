import React, { FC, Fragment } from 'react';
import './profile-employment-item.styles.scss';
import { useSelector } from 'react-redux';
import ProfileEmploymentItemTop from '../profile-employment-item-top/profile-employment-item-top.component';
import { RootState } from '../../../types/root-state';

const ProfileEmploymentItem: FC = () => {
	const workHistory = useSelector(
		(state: RootState) => state.profile.workHistory,
	);

	return (
		<>
			{workHistory.map((item, index) => {
				return (
					<div
						className='profile-employment-item-container'
						key={index}
					>
						{item.type === 'gap' ? (
							<div className='activity-gap-container'>
								<div className='activity-gap-tag'>Gap</div>
							</div>
						) : (
							<ProfileEmploymentItemTop item={item} />
						)}
					</div>
				);
			})}
		</>
	);
};

export default ProfileEmploymentItem;
