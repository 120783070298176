import './external-settings.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import ExternalSettingsContainer from '../../components/external-dashboard-settings/external-settings-container/external-settings-container.component';
import StaffActionModal from '../../components/modals/staff-action-modal/staff-action-modal.component';
import { IStaffMember } from '../../types/interfaces/staff.interface';
import { getAllStaff } from '../../api/api.staff';
import { fireGaEvent, sendGaPageview } from '../../utils/ga-events';

const ExternalSettings: FC = () => {
	const [modalTitle, setModalTitle] = useState('');
	const [modalActionButton, setModalActionButton] = useState('');
	const [userDetails, setUserDetails] = React.useState({
		name: '',
		surname: '',
		email: '',
	});
	const [showModal, setShowModal] = useState(false);
	const [staffDeletionUuid, setStaffDeletionUuid] = useState<string>('');
	const [managerData, setManagerData] = useState<IStaffMember[] | null>(null);
	const [adminData, setAdminData] = useState<IStaffMember[] | null>(null);
	const [viewerData, setViewerData] = useState<IStaffMember[] | null>(null);
	type StaffByRole = Record<string, IStaffMember[]>;

	const handleAddStaff = () => {
		setModalTitle('Add New User');
		setModalActionButton('Add');
		setShowModal(true);
		fireGaEvent(
			'button_click',
			'External Settings Page',
			'Add new user button clicked',
		);
	};

	const handleDeleteStaff = (staffUser: IStaffMember) => {
		setModalTitle('Delete User');
		setModalActionButton('Delete');
		setUserDetails({
			name: staffUser.name,
			surname: staffUser.surname,
			email: '',
		});
		setStaffDeletionUuid(staffUser.uuid);
		setShowModal(true);
		fireGaEvent(
			'button_click',
			'External Settings Page',
			'Delete button clicked',
		);
	};

	const fetchStaff = () => {
		getAllStaff('role').then((res) => {
			setManagerData(null);
			setAdminData(null);
			setViewerData(null);
			const staffByRole: StaffByRole = res.data.data;
			Object.entries(staffByRole).forEach(([role, staffUser]) => {
				if (role === 'Manager') setManagerData(staffUser);
				if (role === 'Admin') setAdminData(staffUser);
				if (role === 'Viewer') setViewerData(staffUser);
			});
		});
	};

	useEffect(() => {
		sendGaPageview('/check/dashboard-settings', 'External Settings Page');
		fetchStaff();
	}, []);

	return (
		<div className='external-settings'>
			<ExternalSettingsContainer
				handleAddStaff={handleAddStaff}
				handleDeleteStaff={handleDeleteStaff}
				managerData={managerData}
				adminData={adminData}
				viewerData={viewerData}
			/>
			{showModal && (
				<StaffActionModal
					modalTitle={modalTitle}
					modalActionButton={modalActionButton}
					userDetails={userDetails}
					setUserDetails={setUserDetails}
					showModal={showModal}
					setShowModal={setShowModal}
					fetchStaff={fetchStaff}
					staffDeletionUuid={staffDeletionUuid}
				/>
			)}
		</div>
	);
};

export default ExternalSettings;
