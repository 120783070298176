import React, { FC } from 'react';
import { Pagination } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../types/root-state';
import { setOrganisationPage } from '../../../../redux/Dashboard/dashboard.actions';

type Props = {
	totalPages: number;
	type: string;
};

const OrganisationPagination: FC<Props> = ({ totalPages, type }) => {
	const dispatch = useDispatch();
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const organisationPage = useSelector(
		(state: RootState) => state.dashboard.organisationPage,
	);

	const handleChange = (event: unknown, page: number) => {
		dispatch(setOrganisationPage(page));
	};

	return (
		<div className='pagination-component'>
			<Pagination
				count={totalPages}
				variant='outlined'
				shape='rounded'
				onChange={handleChange}
				page={organisationPage}
				size={isMobile ? 'small' : 'medium'}
			/>
		</div>
	);
};

export default OrganisationPagination;
