import React, { FC } from 'react';
import searchIcon from '../../../assets/icons/search.svg';
import './external-search.styles.scss';
import { fireGaEvent } from '../../../utils/ga-events';

type Props = {
	placeholder?: string;
	handleChange: (e: any) => void;
	search: string;
	origin?: string;
};

const handleSearchClick = () => {
	fireGaEvent('search', 'Search Bar', 'Search bar clicked');
};

const ExternalSearch: FC<Props> = ({
	placeholder = 'Search',
	handleChange,
	search,
}) => {
	return (
		<div className='external-search-component'>
			<img src={searchIcon} alt='search' />
			<input
				type='text'
				onChange={handleChange}
				onClick={handleSearchClick}
				value={search}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default ExternalSearch;
