import React, { FC } from 'react';
import './orka-button.styles.scss';

type Props = {
	buttonContent: string;
	disabled: boolean;
	emitClicked: any;
};

const OrkaButton: FC<Props> = ({ buttonContent, disabled, emitClicked }) => {
	const handleClick = () => {
		emitClicked();
	};

	return (
		<div className='orka-button'>
			<button
				onClick={handleClick}
				disabled={disabled}
				style={{
					cursor: disabled ? 'not-allowed' : 'pointer',
					opacity: disabled ? 0.5 : 1,
				}}
			>
				{buttonContent}
			</button>
		</div>
	);
};

export default OrkaButton;
