import {
	BUCKETS,
	APPLICANTS,
	SET_BUCKET,
	SET_SEARCH_TERM,
	SET_SORT_VALUE,
	SET_SEARCH_BY_VALUE,
	TOTAL_PAGES,
	SET_PAGE,
	SET_DYNAMIC_TABLE,
	UPDATE_BUCKETS,
	SET_OVERALL_SUBMISSION,
	SET_SELECTED_BUCKET_DATA,
	SIDEBAR_STATE,
	SIDEBAR_BUCKET_DATA,
	FETCH_APPLICANTS,
	RESET_DASHBOARD_STATE,
	SET_SORT_DIRECTION,
	SET_ASSIGN_VAL,
	UPDATE_APPLICANT_FIELD,
	SET_SUBMISSION_QUERY,
	SET_STATUS_QUERY,
	SET_FLOW_QUERY,
	CLEAR_FILTERS,
	SET_TASK_PAGE,
	SET_ORGANISATION_PAGE,
	SUBMISSION_STATUSES_TOTAL,
	SUBMISSION_STATUS_TAB,
	SET_EXTERNAL_TAB,
	SET_COMPANY_QUERY,
} from './dashboard.types';

export const setBuckets = (bucketData) => {
	return {
		type: BUCKETS,
		bucketData,
	};
};

export const setApplicants = (allApplicants) => {
	return {
		type: APPLICANTS,
		allApplicants,
	};
};

export const setBucket = (selectedBucket) => {
	return {
		type: SET_BUCKET,
		selectedBucket,
	};
};

export const setGlobalSearchTerm = (searchTerm) => {
	return {
		type: SET_SEARCH_TERM,
		searchTerm,
	};
};

export const setGlobalSortBy = (sortBy) => {
	return {
		type: SET_SORT_VALUE,
		sortBy,
	};
};

export const setGlobalSortDir = (sortDirection) => {
	return {
		type: SET_SORT_DIRECTION,
		sortDirection,
	};
};

export const setAssignVal = (assignedQuery) => {
	return {
		type: SET_ASSIGN_VAL,
		assignedQuery,
	};
};

export const setSubmissionQuery = (submissionQuery) => {
	return {
		type: SET_SUBMISSION_QUERY,
		submissionQuery,
	};
};

export const setStatusQuery = (statusQuery) => {
	return {
		type: SET_STATUS_QUERY,
		statusQuery,
	};
};

export const setFlowQuery = (flowQuery) => {
	return {
		type: SET_FLOW_QUERY,
		flowQuery,
	};
};

export const setGlobalSearchBy = (searchBy) => {
	return {
		type: SET_SEARCH_BY_VALUE,
		searchBy,
	};
};

export const setTotalPages = (totalPages) => {
	return {
		type: TOTAL_PAGES,
		totalPages,
	};
};

export const setPage = (page) => {
	return {
		type: SET_PAGE,
		page,
	};
};

export const setDynamicTableData = (dynamicTableData) => {
	return {
		type: SET_DYNAMIC_TABLE,
		dynamicTableData,
	};
};

export const setUpdateBuckets = (updateBuckets) => {
	return {
		type: UPDATE_BUCKETS,
		updateBuckets,
	};
};

export const setOverallSubmission = (overallSubmission) => {
	return {
		type: SET_OVERALL_SUBMISSION,
		overallSubmission,
	};
};

export const setSelectedBucketData = (selectedBucketData) => {
	return {
		type: SET_SELECTED_BUCKET_DATA,
		selectedBucketData,
	};
};

export const setSidebarOpen = (sidebarIsOpen) => {
	return {
		type: SIDEBAR_STATE,
		sidebarIsOpen,
	};
};

export const setSidebarBucketMerged = (sidebarBucketData) => {
	return {
		type: SIDEBAR_BUCKET_DATA,
		sidebarBucketData,
	};
};

export const refetchApplicants = () => {
	return {
		type: FETCH_APPLICANTS,
	};
};

export const resetDashboardState = () => {
	return {
		type: RESET_DASHBOARD_STATE,
	};
};

export const updateApplicantField = (workerUuid, field, data) => {
	return {
		type: UPDATE_APPLICANT_FIELD,
		workerUuid,
		field,
		data,
	};
};

export const clearFilters = () => {
	return {
		type: CLEAR_FILTERS,
	};
};

export const setOrganisationPage = (organisationPage) => {
	return {
		type: SET_ORGANISATION_PAGE,
		organisationPage,
	};
};

export const setSubmissionStatusesTotal = (submissionStatusesTotal) => {
	return {
		type: SUBMISSION_STATUSES_TOTAL,
		submissionStatusesTotal,
	};
};

export const setSubmissionStatusTab = (submissionStatusTab) => {
	return {
		type: SUBMISSION_STATUS_TAB,
		submissionStatusTab,
	};
};

export const setExternalTab = (externalTab) => {
	return {
		type: SET_EXTERNAL_TAB,
		externalTab,
	};
};

export const setCompanyQuery = (companyQuery) => {
	return {
		type: SET_COMPANY_QUERY,
		companyQuery,
	};
};
