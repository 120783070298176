import React, { useEffect, FC, useState } from 'react';
import Dashboard from '../../pages/Dashboard/dashboard.component';
import UserProfile from '../../pages/Profile/user-profile.component';
import './check-container.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import MenuContainer from '../menu/menu-container/menu-container.component';
import AddStaff from '../../pages/Add-staff/add-staff.component';
import {
	setTotalPages,
	setDynamicTableData,
	setBuckets,
	setApplicants,
	setSelectedBucketData,
} from '../../redux/Dashboard/dashboard.actions';
import { setAlert, setIsLoading } from '../../redux/General/general.actions';

import * as apiApplicant from '../../api/api.applicant';
import * as utils from '../../utils/utilsFunctions';
import NotFound from '../general/not-found/not-found.component';
import ClearanceCertificatePage from '../../pages/Clearance-certificate/clearance-certificate.component';
import { RootState } from '../../types/root-state';
import ReferenceTable from '../../pages/Organisation-table/organisation-table.component';
import * as apiStaff from '../../api/api.staff';
import * as apiCompany from '../../api/api.company';
import { setAllCompany, setAllStaff } from '../../redux/Auth/auth.actions';
import { IStaffMember } from '../../types/interfaces/staff.interface';
import ResponsiveFilters from '../dashboard/responsive-filters/responsive-filters.component';
import { Routes, Route } from 'react-router-dom';
import DownloadableReport from '../../pages/Downloadable-report/downloadable-report.component';
import { OrganisationProvider } from '../../context/OrganisationContext';
import ErrorBoundary from '../general/error-boundary/error-boundary.component';

const CheckContainer: FC = () => {
	const dispatch = useDispatch();
	const [showFilters, setShowFilters] = useState(false);
	const [isResponsiveView, setIsResponsiveView] = useState(false);
	const bucket = useSelector(
		(state: RootState) => state.dashboard.selectedBucket,
	);
	const searchTerm = useSelector(
		(state: RootState) => state.dashboard.searchTerm,
	);
	const sortBy = useSelector((state: RootState) => state.dashboard.sortBy);
	const searchBy = useSelector(
		(state: RootState) => state.dashboard.searchBy,
	);
	const assignedQuery = useSelector(
		(state: RootState) => state.dashboard.assignedQuery,
	);
	const submissionQuery = useSelector(
		(state: RootState) => state.dashboard.submissionQuery,
	);
	const statusQuery = useSelector(
		(state: RootState) => state.dashboard.statusQuery,
	);
	const sortDirection = useSelector(
		(state: RootState) => state.dashboard.sortDirection,
	);
	const page = useSelector((state: RootState) => state.dashboard.page);
	const updateBuckets = useSelector(
		(state: RootState) => state.dashboard.updateBuckets,
	);
	const refreshGetApplicants = useSelector(
		(state: RootState) => state.dashboard.refreshGetApplicants,
	);
	const selectedBucketData = useSelector(
		(state: RootState) => state.dashboard.selectedBucketData,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const user = useSelector((state: RootState) => state.auth.user);
	const flowQuery = useSelector(
		(state: RootState) => state.dashboard.flowQuery,
	);
	const companyQuery = useSelector(
		(state: RootState) => state.dashboard.companyQuery,
	);

	if (role === 'manager' || role === 'admin' || role === 'viewer') {
		return null;
	}

	useEffect(() => {
		const fetchData = (): void => {
			dispatch(setIsLoading(true));
			apiApplicant
				.getApplicants(
					bucket,
					searchTerm,
					sortBy,
					searchBy,
					page,
					sortDirection,
					assignedQuery,
					submissionQuery,
					statusQuery,
					flowQuery,
					companyQuery,
				)
				.then(({ data }) => {
					const total = data.meta.total;
					const totalPages = Math.ceil(total / 20);
					dispatch(setIsLoading(false));
					if (!selectedBucketData) {
						const firstBucket = {
							text: 'All Applicants',
							amount: data.meta.custom.buckets.all,
						};
						dispatch(setSelectedBucketData(firstBucket));
					} else {
						const bucketWithUpdatedAmount = {
							...selectedBucketData,
							amount: total,
						};
						dispatch(
							setSelectedBucketData(bucketWithUpdatedAmount),
						);
					}
					dispatch(setBuckets(data.meta.custom.buckets));
					dispatch(setApplicants(data.items));
					dispatch(setTotalPages(totalPages));
				})
				.catch(() => {
					dispatch(setIsLoading(false));
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to get applicants',
							isVisible: true,
						}),
					);
				});
		};

		if (role !== 'ambassador') {
			fetchData();
		} else {
			dispatch(setApplicants([]));
		}
	}, [
		role,
		bucket,
		searchTerm,
		sortBy,
		searchBy,
		page,
		dispatch,
		updateBuckets,
		refreshGetApplicants,
		sortDirection,
		assignedQuery,
		submissionQuery,
		statusQuery,
		flowQuery,
		companyQuery,
	]);

	useEffect(() => {
		apiStaff
			.getAllStaff()
			.then((res) => {
				if (res.status === 404) {
					throw res;
				} else {
					const found = res.data.data.find(
						(element: IStaffMember) => element.email === user.email,
					);
					const filteredArray = res.data.data.filter(
						(element: IStaffMember) => element.email !== user.email,
					);
					dispatch(
						setAllStaff(
							handleAssignColor([found, ...filteredArray]),
						),
					);
				}
			})
			.catch((err) => {
				throw err;
			});
	}, []);

	useEffect(() => {
		apiCompany
			.getAllCompanies()
			.then((res) => {
				dispatch(setAllCompany(res.data));
			})
			.catch((err) => {
				throw err;
			});
	}, []);

	useEffect(() => {
		const dynamicData = utils.getDynamicTableData(bucket);
		dispatch(setDynamicTableData(dynamicData));
	}, [bucket, dispatch]);

	useEffect(() => {
		if (window.innerWidth > 1000) {
			setIsResponsiveView(false);
		} else {
			setIsResponsiveView(true);
		}
	}, [showFilters]);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1000) {
				setIsResponsiveView(false);
			} else {
				setIsResponsiveView(true);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [showFilters]);

	const handleAssignColor = (staffArray: IStaffMember[]) => {
		const colors = [
			'color-green',
			'color-blue',
			'color-orange',
			'color-purple ',
			'color-red',
		];

		return staffArray.map((member: IStaffMember, index: number) => {
			member.color = colors[index % colors.length];
			member.selected = false;
			return member;
		});
	};

	return (
		<div className='check-container'>
			{showFilters && isResponsiveView ? (
				<ResponsiveFilters setShowFilters={setShowFilters} />
			) : (
				<>
					<ErrorBoundary applicantWorkerUuid='None'>
						<MenuContainer />
					</ErrorBoundary>
					<Routes>
						<Route
							path='/'
							element={
								<Dashboard
									showFilters={showFilters}
									setShowFilters={setShowFilters}
								/>
							}
						/>
						<Route
							path='/user/:id'
							element={
								<OrganisationProvider>
									<UserProfile />
								</OrganisationProvider>
							}
						/>

						<Route path='/settings' element={<AddStaff />} />
						<Route
							path='/organisations'
							element={<ReferenceTable />}
						/>
						<Route
							path='/report'
							element={<DownloadableReport />}
						/>
						<Route
							path='/certificate'
							element={<ClearanceCertificatePage />}
						/>

						<Route
							path='*'
							element={
								<NotFound type='noData' pageNotFound={true} />
							}
						/>
					</Routes>
				</>
			)}
		</div>
	);
};

export default CheckContainer;
