import ReactGA from 'react-ga4';
import store from '../redux/store';
import { RootState } from '../types/root-state';

export const fireGaEvent = (
	eventName: string,
	category: string,
	action: string,
): void => {
	const state: RootState = store.getState();
	const staffRole = state.auth.user.role ? state.auth.user.role : 'Pre Login';
	const companyUuid = state.auth.user.company
		? state.auth.user.company.uuid
		: 'Pre Login';
	ReactGA.event(eventName, {
		event_category: `Company Portal - ${category}`,
		event_action: action,
		company_uuid: companyUuid,
		role: staffRole,
	});
};

export const sendGaPageview = (page: string, title: string): void => {
	ReactGA.send({
		hitType: 'pageview',
		page,
		title,
	});
};
