import './external-check-header.styles.scss';
import React, { FC, SetStateAction, useState } from 'react';
import Modal from '../../modals/modal/modal.component';
import { fireGaEvent } from '../../../utils/ga-events';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { tabData } from '../../../utils/externalTabsData';
import {
	ExtInviteModalFlow,
	INewCandidate,
	ISubmissionStatusesTotal,
	InviteRespSubmit,
	NiApplicantFound,
} from '../../../types/interface';
import ExtInviteApplicantNi from '../../external-settings/external-invite-applicant/external-invite-applicant-ni/external-invite-applicant-ni.component';
import ExtInviteApplicantDetails from '../../external-settings/external-invite-applicant/external-invite-applicant-details/external-invite-applicant-details.component';
import ExtInviteApplicantResponse from '../../external-settings/external-invite-applicant/external-invite-applicant-response/external-invite-applicant-response.component';

const ExternalCheckHeader: FC = () => {
	const [showAddApplicantModal, setShowAddApplicantModal] = useState(false);
	const staffRole = useSelector((state: RootState) => state.auth.role);
	const [foundApplicant, setFoundApplicant] = useState<NiApplicantFound>({
		exists: true,
		passportDetails: null,
	});
	const [passportFormData, setPassportFormData] = useState<INewCandidate>({
		name: '',
		surname: '',
		email: '',
		contactNumber: '',
		nationalInsuranceNumber: '',
	});
	const [responseInvite, setResponseInvite] =
		useState<InviteRespSubmit>(null);
	const [inviteApplicantFlow, setInviteApplicantFlow] =
		useState<ExtInviteModalFlow>(1);

	const applicant = useSelector(
		(state: RootState) => state.dashboard.allApplicants,
	);

	const tabStatusData = useSelector(
		(state: RootState) => state.dashboard.submissionStatusesTotal,
	);

	// Calculate total values
	const total =
		(tabStatusData && tabStatusData[0] && tabStatusData[0].total) ||
		(tabData && tabData[0] && tabData[0].total);

	// Map data array and returns percentage of each count with their respective color
	const toPercent = (slice: any, max: any) => {
		return (slice / max) * 100;
	};

	const progress =
		(tabStatusData && tabStatusData.slice(1)) ||
		(tabData && tabData.slice(1))
			? (
					(tabStatusData && tabStatusData.slice(1)) ||
					(tabData && tabData.slice(1))
				).map((item: ISubmissionStatusesTotal) => {
					let hex;
					switch (item.status) {
						case 'AwaitingSubmission':
							hex = '#9aa5b1';
							break;
						case 'InProgress':
							hex = '#485beb';
							break;
						case 'Limited':
							hex = '#f8c96a';
							break;
						case 'Full':
							hex = '#1dc7b2';
							break;
						case 'Cancelled':
							hex = '#ec8079';
							break;
						default:
							hex = '';
					}
					return {
						...item,
						color: hex,
						percent: toPercent(item.total, total),
					};
				})
			: [];

	const applicantModal = (e: SetStateAction<boolean>) => {
		fireGaEvent(
			'button_click',
			'Dashboard',
			'Invite Applicant button clicked',
		);
		setResponseInvite(null);
		setShowAddApplicantModal(e);
		setInviteApplicantFlow(1);
	};
	const inviteApplicantNi = (e: SetStateAction<NiApplicantFound>) => {
		setFoundApplicant(e);
		setInviteApplicantFlow(2);
	};
	const responseSubmit = (e: SetStateAction<InviteRespSubmit>) => {
		setResponseInvite(e);
		setInviteApplicantFlow(3);
	};
	return (
		<div className='external-check-header'>
			<div className='external-check-info'>
				<div className='external-applicant-count'>
					<span className='count'>{total}</span>
					Applicants
				</div>
				<div className='external-applicant-progress'>
					{progress.map((tabData: ISubmissionStatusesTotal) => (
						<span
							key={tabData.status}
							className={`status-${tabData.status}`}
							style={{
								backgroundColor: tabData.color,
								width: tabData.percent + '%',
							}}
						></span>
					))}
				</div>
				<p className='found-applicants'>
					Found {applicant.length}{' '}
					{applicant.length === 1 ? 'applicant' : 'applicants'}
				</p>
			</div>
			<div className='external-check-action'>
				{staffRole !== 'viewer' && (
					<button
						className='external-check-button'
						onClick={() => {
							applicantModal(true);
						}}
					>
						Invite Applicant
					</button>
				)}

				{showAddApplicantModal && (
					<Modal
						modalContentHeight='auto'
						title={
							responseInvite === 'Invited' ||
							responseInvite === 'Purchased'
								? ''
								: 'Invite an Applicant'
						}
						setShowModal={applicantModal}
						showModal={showAddApplicantModal}
					>
						{inviteApplicantFlow === 1 && (
							<ExtInviteApplicantNi
								setShowModal={applicantModal}
								nextAction={inviteApplicantNi}
							/>
						)}
						{inviteApplicantFlow === 2 && (
							<ExtInviteApplicantDetails
								setApplicantFlow={setInviteApplicantFlow}
								setPassportFormData={setPassportFormData}
								foundApplicant={foundApplicant}
								responseSubmit={responseSubmit}
							/>
						)}
						{inviteApplicantFlow === 3 &&
							foundApplicant.passportDetails && (
								<ExtInviteApplicantResponse
									setShowModal={applicantModal}
									setApplicantFlow={setInviteApplicantFlow}
									inviteRespSubmit={responseInvite}
									setResponseInvite={setResponseInvite}
									passportFormData={passportFormData}
									passportDetails={
										foundApplicant.passportDetails
									}
								/>
							)}
					</Modal>
				)}
			</div>
		</div>
	);
};

export default ExternalCheckHeader;
