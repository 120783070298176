import React, { useState, useEffect, FC, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProfileEmployment from '../profile-employment/profile-employment.component';
import Stage from '../stage/stage.component';
import {
	getFiles,
	setYotiDetails,
} from '../../../redux/Profile/profile.actions';
import * as docsApi from '../../../api/api.docs';
import { setAlert, setIsEditDisabled } from '../../../redux/General/general.actions';
import './profile-primary-container.styles.scss';
import RtwSection from '../rtw-section/rtw-section.component';
import ActivityDetails from '../activity-details/activity-details.component';
import ProfileLicences from '../profile-licences/profile-licences';
import ProfileSection from '../profile-section/profile-section.component';
import ProfileAddressHistory from '../profile-address-history/profile-address-history.component';
import ProfileDocumentBucket from '../profile-document-bucket/profile-document-bucket.component';
import ProfileChecklist from '../profile-checklist/profile-checklist.component';
import FaceToFaceSection from '../face-to-face-section/face-to-face-section.component';
import ProfileCriminalBankruptcy from '../profile-criminal-bankruptcy/profile-criminal-bankruptcy';
import StageNotes from '../stage-notes/stage-notes.component';
import StageRisk from '../stage-risk/stage-risk.component';
import { RootState } from '../../../types/root-state';
import {
	IFileItem,
	YotiDocs,
} from '../../../types/interfaces/document.interface';
import CancelPause from '../../general/profile-cancel-pause/profile-cancel-pause.component';
import * as apiApplicant from '../../../api/api.applicant';
import * as apiId from '../../../api/api.identity';
import { saveLicenses } from '../../../redux/Profile/profile.actions';
import blueTick from '../../../assets/icons/blue-tick.svg';
import LivenessCheck from '../liveness-check/liveness-check.component';
import YotiOverwrite from '../yoti-overwrite/yoti-overwrite';
import { IYotiData } from '../../../types/interfaces/rtw.interface';
import ProfileResetRTW from '../profile-reset-rtw/profile-reset-rtw';
import ProfileDocumentDetails from '../profile-document-details/profile-document-details.component';
import { YotiDocumentDetails } from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';
import { CheckType, StaffRole } from '../../../types/enum';

const ProfilePrimaryContainer: FC = () => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const yotiDetails = useSelector(
		(state: RootState) => state.profile.yotiDetails,
	);
	const showActivity = useSelector(
		(state: RootState) => state.profile.showActivity,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const activityItem = useSelector(
		(state: RootState) => state.profile.activityItem,
	);
	const stageData = useSelector(
		(state: RootState) => state.profile.stageData,
	);

	const { ambassadorCheckStatus } = useSelector(
		(state: RootState) => state.profile,
	);
	const licences = useSelector((state: RootState) => state.profile.licences);
	const files = useSelector((state: RootState) => state.profile.files);
	const [rtwFileArray, setRtwFileArray] = useState<IFileItem[]>([]);
	const [rtwManualDocArray, setRtwManualDocArray] = useState<IFileItem[]>([]);
	const [addressFileArray, setAddressFileArray] = useState<IFileItem[]>([]);
	const [niFileArray, setNiFileArray] = useState<IFileItem[]>([]);
	const [additionalFileArray, setAdditionalFileArray] = useState<IFileItem[]>(
		[],
	);
	const [faceToFaceFileArray, setFaceToFaceFileArray] = useState<IFileItem[]>(
		[],
	);
	const [yotiFaceToFaceArray, setYotiFaceToFaceArray] = useState<YotiDocs[]>(
		[],
	);
	const [yotiRtwArray, setYotiRtwArray] = useState<YotiDocs[]>([]);
	const [allLicences, setAllLicences] = useState<any>([]);
	const [showSection, setShowSection] = useState<boolean>(true);
	const primaryContainerMainRef = useRef<HTMLDivElement>(null);
	const [refetch, setRefetch] = useState<boolean>(false);
	const [yotiDocumentDetails, setYotiDocumentDetails] =
		useState<YotiDocumentDetails | null>();

	useEffect(() => {
		if (
			role === StaffRole.CONTROLLER ||
			role === StaffRole.SCREENER
		) {
			dispatch(setIsEditDisabled(
				applicant.checkType === CheckType.RTW || 
				applicant.checkType === CheckType.RTWBS7858
			))
		}
	});

	useEffect(() => {
		if (applicant.workerUuid) {
			getDocuments();
			setYotiDocumentDetails(null);
			getYotiDetails();
			getYotiDocuments();
		}
	}, [applicant.workerUuid, dispatch, applicant.uuid, refetch]);

	useEffect(() => {
		const processFiles = () => {
			const rtwFiles: IFileItem[] = [];
			const rtwManualDocFiles: IFileItem[] = [];
			const addressFiles: IFileItem[] = [];
			const niFiles: IFileItem[] = [];
			const additionalFiles: IFileItem[] = [];
			const faceToFaceFiles: IFileItem[] = [];
			if (files && files.length > 0) {
				let allFiles = files.map((file: any) => {
					if (typeof file.tags === 'string') {
						file.tags = file.tags
							.replace(/(?!,)\W/g, '')
							.split(',');
					}
					file.status = file.tags[0];
					file.docType = file.tags[1];
					return file;
				});

				allFiles = allFiles.filter((file) => file.deletedAt === null);
				allFiles.forEach((f) => {
					if (f.docType === 'rightToWork') {
						rtwFiles.push(f);
					} else if (f.docType === 'rightToWorkManual') {
						rtwManualDocFiles.push(f);
					} else if (
						f.docType === 'proofOfCurrentAddress' ||
						f.docType === 'proofOfAddress'
					) {
						addressFiles.push(f);
					} else if (
						f.docType === 'proofOfNINumber' ||
						f.docType === 'proofOfNI'
					) {
						niFiles.push(f);
					} else if (f.docType === 'additionalDocs') {
						additionalFiles.push(f);
					} else if (f.docType === 'faceToFace') {
						faceToFaceFiles.push(f);
					}
				});
				setRtwFileArray(rtwFiles);
				setRtwManualDocArray(rtwManualDocFiles);
				setAddressFileArray(addressFiles);
				setNiFileArray(niFiles);
				setAdditionalFileArray(additionalFiles);
				setFaceToFaceFileArray(faceToFaceFiles);
			}
		};
		processFiles();
	}, [JSON.stringify(files)]);

	useEffect(() => {
		if (showActivity && primaryContainerMainRef.current) {
			primaryContainerMainRef.current.scrollTo(0, 0);
		}
	}, [showActivity]);

	useEffect(() => {
		if (allLicences.length > 0) {
			handleSave().catch((err) => {
				throw err;
			});
		}
	}, [allLicences.length]);

	const handleSiaClicked = () => {
		if (licences && licences.length > 0) {
			return handleGetLicensesFromSia().catch((err) => {
				throw err;
			});
		} else {
			dispatch(
				setAlert({
					type: 'info',
					message: 'Applicant has no Licences',
					isVisible: true,
				}),
			);
		}
	};

	const handleGetLicensesFromSia = (): Promise<any> => {
		const payload = {
			name: licences[0].name,
			surname: licences[0].surname,
			DOB: applicant.DOB,
		};

		return apiApplicant
			.getAllLicences(applicant.workerUuid, payload)
			.then((res: any) => {
				setAllLicences([...allLicences, ...res.data.data]);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to get licences from SIA',
						isVisible: true,
					}),
				);
			});
	};

	const handleSave = (): Promise<any> => {
		const data = {
			qualifications: allLicences,
		};
		return apiApplicant
			.saveLicences(applicant.workerUuid, data)
			.then((res) => {
				dispatch(saveLicenses(res.data.data.items));
				let message = '';

				if (res.data.data.duplicates.length > 0) {
					message += `${res.data.data.duplicates.length} licences are already saved.`;
				}

				if (res.data.data.items.length > 0) {
					message += `${res.data.data.items.length} licences saved`;
				}

				dispatch(
					setAlert({
						type: 'success',
						message: message,
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to update licence',
						isVisible: true,
					}),
				);
			});
	};

	const getYotiDetails = () => {
		return apiId
			.yotiDetails(applicant.workerUuid)
			.then(({ data }) => {
				const { candidate } = data.data || '';
				if (candidate) {
					const yotiData: IYotiData = {
						override: candidate.override,
						flow: candidate.yotiSession.flow,
						sessionStatus: candidate.yotiSession.sessionStatus,
						sessionId: candidate.yotiSession.sessionId,
						sessionUuId: candidate.yotiSession.uuid,
					};
					dispatch(setYotiDetails(yotiData));
					if (
						candidate.yotiSession &&
						candidate.yotiSession.details
					) {
						setYotiDocumentDetails(candidate.yotiSession.details);
					}
				}
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to get Rtw details',
						isVisible: true,
					}),
				);
			});
	};
	const getYotiDocuments = () => {
		apiId
			.yotiDocuments(applicant.workerUuid)
			.then(({ data }) => {
				const { yotiRtwArray, yotiFaceToFaceArray } =
					utils.mapYotiDocsImages(data);
				setYotiRtwArray(yotiRtwArray);
				setYotiFaceToFaceArray(yotiFaceToFaceArray);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error getting yoti documents',
						isVisible: true,
					}),
				);
			});
	};
	const getDocuments = () => {
		docsApi
			.getDocuments(applicant.workerUuid)
			.then(({ data }) => {
				const files = data.data.filter(
					(file: IFileItem) => file.deletedAt === null,
				);
				dispatch(getFiles(files));
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Error getting documents',
						isVisible: true,
					}),
				);
			});
	};

	const getLicenceCheckedDate = () => {
		const SIAData = { ...stageData[2] };
		const licenceCheckedData = SIAData.stageCompletedAt ?? false;
		return (
			licenceCheckedData &&
			new Date(licenceCheckedData).toLocaleDateString('en-GB')
		);
	};

	const licenceCheckedDate = getLicenceCheckedDate();

	const livenessCheck = () => {
		if (!yotiDetails || yotiFaceToFaceArray.length === 0) {
			return;
		}
		return (
			<LivenessCheck
				fileArray={yotiFaceToFaceArray}
				showContainerClass={true}
			/>
		);
	};
	const showF2fSection = () => {
		if (
			ambassadorCheckStatus &&
			ambassadorCheckStatus.status !== 404 &&
			ambassadorCheckStatus.status !== 'started'
		) {
			return (
				<>
					<FaceToFaceSection />
					<ProfileDocumentBucket
						tag='faceToFace'
						fileArray={faceToFaceFileArray}
					/>
				</>
			);
		} else {
			return;
		}
	};
	return (
		<div className='profile-primary-container'>
			<div
				className='primary-container-main'
				ref={primaryContainerMainRef}
			>
				{!showActivity &&
					role !== 'ambassador' &&
					stageData.length === 9 && (
						<>
							<CancelPause />
							<Stage
								title='Right to Work and ID Verification'
								stageData={stageData[0]}
							>
								{yotiDocumentDetails && (
									<ProfileSection
										title='Right To Work Check - Passport'
										expandable={true}
										setShowSection={setShowSection}
										showSection={showSection}
									>
										{showSection && (
											<ProfileDocumentDetails
												refetch={refetch}
												setRefetch={setRefetch}
												yotiDocumentDetails={
													yotiDocumentDetails
												}
											/>
										)}
									</ProfileSection>
								)}
								<RtwSection
									rtwFileArray={rtwFileArray}
									niFileArray={niFileArray}
									rtwManualDocArray={rtwManualDocArray}
									yotiFileArray={yotiRtwArray}
								/>

								<ProfileSection title='Reset Right to Work'>
									<ProfileResetRTW resetType='RTW' />
								</ProfileSection>

								<ProfileSection title='Identity Verification'>
									{livenessCheck()}
									{yotiDetails &&
										yotiDetails.flow === 'other-rtw' && (
											<YotiOverwrite />
										)}
									{!yotiDetails && <>{showF2fSection()}</>}
								</ProfileSection>

								<ProfileSection title='Reset ID Check'>
									<ProfileResetRTW resetType='ID' />
								</ProfileSection>

								<ProfileSection title='Notes'>
									<StageNotes
										rtwStage={stageData[0]}
										stage={stageData[7]}
									/>
								</ProfileSection>
							</Stage>
							<Stage
								title='Activity History'
								stageData={stageData[1]}
							>
								<ProfileEmployment />
								<ProfileSection title='Notes'>
									<StageNotes stage={stageData[1]} />
								</ProfileSection>
								<ProfileSection title='Risks'>
									<StageRisk stage={stageData[1]} />
								</ProfileSection>
							</Stage>

							<Stage
								title='SIA Licences'
								stageData={stageData[2]}
								handleSiaClicked={handleSiaClicked}
							>
								{licenceCheckedDate && (
									<div className='licence-checked-container'>
										<div className='content'>
											<img
												src={blueTick}
												alt='licence checked'
											/>
											<span className='faded-text'>
												Licence checked on{' '}
											</span>
											<span className='licence-checked-date'>
												{licenceCheckedDate}
											</span>
										</div>
									</div>
								)}

								<ProfileSection title='Licences'>
									<ProfileLicences />
								</ProfileSection>
								<ProfileSection title='Notes'>
									<StageNotes stage={stageData[2]} />
								</ProfileSection>
								<ProfileSection title='Risks'>
									<StageRisk stage={stageData[2]} />
								</ProfileSection>
							</Stage>

							<Stage
								title='Address History'
								stageData={stageData[3]}
							>
								<ProfileSection title='Timeline'>
									<ProfileAddressHistory />
								</ProfileSection>
								<ProfileSection title='Documents'>
									<ProfileDocumentBucket
										tag='proofOfAddress'
										fileArray={addressFileArray}
									/>
								</ProfileSection>

								<ProfileSection title='Notes'>
									<StageNotes stage={stageData[3]} />
								</ProfileSection>
							</Stage>

							<Stage
								title='Companies House Check'
								stageData={stageData[4]}
							>
								<ProfileSection title='Notes'>
									<StageNotes stage={stageData[4]} />
								</ProfileSection>
								<ProfileSection title='Risks'>
									<StageRisk stage={stageData[4]} />
								</ProfileSection>
							</Stage>

							<Stage
								title='Public Record / Global Watchlist Check'
								stageData={stageData[5]}
							>
								<ProfileSection title='Notes'>
									<StageNotes stage={stageData[5]} />
								</ProfileSection>
								<ProfileSection title='Risks'>
									<StageRisk stage={stageData[5]} />
								</ProfileSection>
							</Stage>

							<Stage title='Disclosures' stageData={stageData[6]}>
								<ProfileSection title='Details'>
									<ProfileCriminalBankruptcy />
								</ProfileSection>
								<ProfileSection title='Notes'>
									<StageNotes stage={stageData[6]} />
								</ProfileSection>
								<ProfileSection title='Risks'>
									<StageRisk stage={stageData[6]} />
								</ProfileSection>
							</Stage>

							<Stage
								title='Screening Controller Review'
								stageData={stageData[8]}
							>
								<ProfileSection title='Checklist'>
									<ProfileChecklist />
								</ProfileSection>

								<ProfileSection title='Notes'>
									<StageNotes stage={stageData[8]} />
								</ProfileSection>
							</Stage>

							<Stage title='Additional Files' clickable={false}>
								<ProfileSection title='Documents'>
									<ProfileDocumentBucket
										tag='additionalDocs'
										fileArray={additionalFileArray}
									/>
								</ProfileSection>
							</Stage>
						</>
					)}
				{!showActivity && role === 'ambassador' && (
					<>
						<Stage
							title='Right to Work and ID Verification'
							stageData={stageData[0]}
						>
							<RtwSection
								rtwFileArray={rtwFileArray}
								niFileArray={niFileArray}
								rtwManualDocArray={rtwManualDocArray}
								yotiFileArray={yotiRtwArray}
							/>

							<ProfileSection title='Identity Verification'>
								{livenessCheck()}
								{yotiDetails &&
									yotiDetails.flow === 'other-rtw' && (
										<YotiOverwrite />
									)}
								{!yotiDetails && <>{showF2fSection()}</>}
							</ProfileSection>
							<ProfileSection title='Notes'>
								<StageNotes
									rtwStage={stageData[0]}
									stage={stageData[7]}
								/>
							</ProfileSection>
						</Stage>

						<Stage title='Address History'>
							<ProfileSection title='Timeline'>
								<ProfileAddressHistory />
							</ProfileSection>
							<ProfileSection title='Documents'>
								<ProfileDocumentBucket
									tag='proofOfAddress'
									fileArray={addressFileArray}
								/>
							</ProfileSection>
						</Stage>
					</>
				)}
				{showActivity && <ActivityDetails item={activityItem} />}
			</div>
		</div>
	);
};

export default ProfilePrimaryContainer;
