export interface Tab {
	id: string;
	tabTitle: string;
	textColor: string;
}

export const tabs: Tab[] = [
	{
		id: '1',
		tabTitle: 'All',
		textColor: '#4A4A4A',
	},
	{
		id: '2',
		tabTitle: 'Awaiting Submission',
		textColor: '#9AA5B1',
	},
	{
		id: '3',
		tabTitle: 'In Progress',
		textColor: '#485BEB',
	},
	{
		id: '4',
		tabTitle: 'Limited',
		textColor: '#ECB442',
	},
	{
		id: '5',
		tabTitle: 'Full',
		textColor: '#09AD99',
	},
	{
		id: '6',
		tabTitle: 'Cancelled',
		textColor: '#EC8079',
	},
];

export const tabData = [
	{ status: 'all', total: 0 },
	{ status: 'awaitingSubmission', total: 0 },
	{ status: 'inProgress', total: 0 },
	{ status: 'limited', total: 0 },
	{ status: 'full', total: 0 },
	{ status: 'cancelled', total: 0 },
];
