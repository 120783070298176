import React, { FC } from 'react';
import './mobile-header-buckets.scss';
import logout from '../../../assets/icons/sidebar/logout.svg';
import addStaff from '../../../assets/icons/sidebar/add-staff.svg';
import settings from '../../../assets/icons/sidebar/settings.svg';
import SidebarBucketContainer from '../sidebar-buckets/sidebar-buckets.component';

import { useSelector } from 'react-redux';
import { BucketState, BucketData } from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import { ILinkState } from '../../../types/interfaces/misc.interface';
import SearchContainer from '../../general/search-container/search-container.component';

type Props = {
	handleLogout: (event: React.MouseEvent<HTMLDivElement>) => void;
	state: BucketState;
	handleSelectBucket: (bucket: BucketData) => void;
	handleAddStaff: (event: React.MouseEvent<HTMLDivElement>) => void;
	handleOpenOrganization: (event: React.MouseEvent<HTMLDivElement>) => void;
	selectedState: ILinkState;
};

const MobileHeaderBuckets: FC<Props> = ({
	handleLogout,
	state,
	handleSelectBucket,
	handleAddStaff,
	handleOpenOrganization,
	selectedState,
}) => {
	const role = useSelector((state: RootState) => state.auth.role);

	return (
		<div className='mobile-header-buckets'>
			{role !== 'ambassador' && (
				<>
					<div className='search-container'>
						<SearchContainer />
					</div>
					<SidebarBucketContainer
						handleSelectBucket={handleSelectBucket}
						state={state}
						sidebarIsOpen={true}
					/>
				</>
			)}

			{role !== 'ambassador' && (
				<div
					className={`sidebar-action-lg ${selectedState.settings ? 'active-tab' : ''}`}
					onClick={handleAddStaff}
				>
					<img src={settings} alt='add staff' />
					<p className='link-text'> Settings </p>
				</div>
			)}

			{role !== 'ambassador' && (
				<div
					className={`sidebar-action-lg ${selectedState.organisations ? 'active-tab' : ''}`}
					onClick={handleOpenOrganization}
				>
					<img src={addStaff} alt='add staff' />
					<p className='link-text'> Organisation Database </p>
				</div>
			)}

			<div className='sidebar-action-lg' onClick={handleLogout}>
				<img src={logout} alt='logout' />
				<p className='logout-txt'>Logout</p>
			</div>

			<div className='spacer'></div>
		</div>
	);
};

export default MobileHeaderBuckets;
