import React, { useState, useEffect, FC } from 'react';
import './menu-container.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	setBucket,
	setPage,
	setSelectedBucketData,
	setSidebarBucketMerged,
	resetDashboardState,
	refetchApplicants,
} from '../../../redux/Dashboard/dashboard.actions.js';
import { clearProfileData } from '../../../redux/Profile/profile.actions';
import { clearAuth } from '../../../redux/Auth/auth.actions';
import { bucketDataFile } from '../../../utils/data';
import MobileHeader from '../mobile-header/mobile-header';
import Sidebar from '../sidebar/sidebar.component';
import { RootState } from '../../../types/root-state';
import { BucketData, BucketState } from '../../../types/interface';
import { ILinkState } from '../../../types/interfaces/misc.interface';
import { useNavigate, useLocation } from 'react-router-dom';
import * as authApi from '../../../api/api.auth';
import { setAlert } from '../../../redux/General/general.actions';

type LinkOptions = 'organisations' | 'settings';

const initialLinkState = {
	organisations: false,
	settings: false,
};

const MenuContainer: FC = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const bucketData = useSelector(
		(state: RootState) => state.dashboard.bucketData,
	);
	const selectedBucket = useSelector(
		(state: RootState) => state.dashboard.selectedBucket,
	);
	const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [mobileBucketsOpen, setMobileBucketsOpen] = useState<boolean>(false);
	const [state, setState] = useState<BucketState>({
		buckets: [],
	});
	const [selectedState, setSelectedState] = useState<ILinkState>({
		...initialLinkState,
	});

	const dispatch = useDispatch();

	useEffect(() => {
		if (window.innerWidth > 700) {
			setIsMobile(false);
		} else {
			setIsMobile(true);
		}
	}, []);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 700) {
				setIsMobile(false);
			} else {
				setIsMobile(true);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		let array: BucketData[] = [];
		// eslint-disable-next-line prefer-const
		for (let key in bucketData) {
			array = [...array, { name: [key][0], amount: bucketData[key] }];
		}
		const merged = bucketDataFile.map((itm) => ({
			...array.find(
				(item: BucketData) => item.name === itm.value && item,
			),
			...itm,
		}));

		const handleSelectedOnMount = merged.map((bucket) => {
			bucket.selected = bucket.name === selectedBucket;
			if (pathname !== '/check') bucket.selected = false;
			return bucket;
		});

		setState({
			...state,
			buckets: handleSelectedOnMount,
		});

		dispatch(setSidebarBucketMerged(handleSelectedOnMount));
	}, [JSON.stringify(bucketData)]);

	useEffect(() => {
		if (pathname !== '/check' && state.buckets.length > 0) {
			const bucketsWithNoneSelected = state.buckets.map((bucket) => {
				return {
					...bucket,
					selected: false,
				};
			});
			setState({
				...state,
				buckets: bucketsWithNoneSelected,
			});
		}

		if (
			pathname.includes('settings') ||
			pathname.includes('organisations')
		) {
			const selectedPath = pathname.slice(7) as LinkOptions;
			if (!selectedState[selectedPath]) selectLink(selectedPath);
		} else if (pathname === '/check') {
			if (state.buckets.length === 4) {
				setSelectedState({ ...initialLinkState });
				const handleSelectedOnBack = state.buckets.map((bucket) => {
					bucket.selected = bucket.name === selectedBucket;
					return bucket;
				});
				setState({
					...state,
					buckets: handleSelectedOnBack,
				});

				setMobileBucketsOpen(true);
			}
		} else {
			setSelectedState({ ...initialLinkState });
		}
	}, [pathname]);

	const handleSelectBucket = (bucket: BucketData) => {
		selectLink('unselect');
		dispatch(refetchApplicants());
		setMobileBucketsOpen(false);
		navigate(`/check`);
		selectBucketHelper(bucket);
		dispatch(setBucket(bucket.name));
		dispatch(setPage(1));
		dispatch(setSelectedBucketData(bucket));
	};

	const selectBucketHelper = (bucket: BucketData) => {
		const data = state.buckets.map((b) => {
			b.selected = b.name === bucket.name;
			return b;
		});

		setState({
			buckets: data,
		});
	};

	const handleLogout = () => {
		authApi
			.logout()
			.then((res) => {
				dispatch(
					setAlert({
						type: res && res.status === 201 ? 'info' : 'error',
						message:
							res && res.status === 201
								? 'Successfully logged out'
								: 'Unable to log out. Please clear your cookies and close your browser.',
						isVisible: true,
					}),
				);
				return;
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
		selectLink('unselect');
		setMobileBucketsOpen(false);
		setIsLoggedOut(true);
		dispatch(clearProfileData());
		dispatch(resetDashboardState());
		dispatch(clearAuth());
		navigate(`/login`, { replace: true });
	};

	const handleAddStaff = () => {
		selectLink('settings');
		setMobileBucketsOpen(false);
		navigate(`/check/settings`);
	};

	const handleOpenOrganization = () => {
		selectLink('organisations');
		setMobileBucketsOpen(false);
		navigate(`/check/organisations`);
	};

	const selectLink = (link: string) => {
		let key: keyof ILinkState;

		for (key in selectedState) {
			if (link === key) {
				selectedState[key] = true;
			} else {
				selectedState[key] = false;
			}
		}
		setSelectedState(selectedState);
	};

	return (
		<div>
			{!isMobile ? (
				<Sidebar
					handleLogout={handleLogout}
					state={state}
					handleSelectBucket={handleSelectBucket}
					handleAddStaff={handleAddStaff}
					handleOpenOrganization={handleOpenOrganization}
					selectedState={selectedState}
				/>
			) : (
				<div>
					<MobileHeader
						handleLogout={handleLogout}
						state={state}
						handleSelectBucket={handleSelectBucket}
						handleAddStaff={handleAddStaff}
						handleOpenOrganization={handleOpenOrganization}
						isLoggedOut={isLoggedOut}
						mobileBucketsOpen={mobileBucketsOpen}
						setMobileBucketsOpen={setMobileBucketsOpen}
						selectedState={selectedState}
					/>
				</div>
			)}
		</div>
	);
};

export default MenuContainer;
