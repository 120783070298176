import '../external-tabs/external-tabs.styles.scss';
import React, { FC, useEffect } from 'react';
import { fireGaEvent } from '../../../utils/ga-events';
import { tabs, tabData } from '../../../utils/externalTabsData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import {
	setExternalTab,
	setPage,
	setSubmissionStatusTab,
} from '../../../redux/Dashboard/dashboard.actions';
import ExternalDashboardTable from '../external-dashboard-table/external-dashboard-table.component';

const ExternalTabs: FC = () => {
	const dispatch = useDispatch();

	const currentTab = useSelector(
		(state: RootState) => state.dashboard.externalTab,
	);

	const role = useSelector((state: RootState) => state.auth.role);

	const applicants = useSelector(
		(state: RootState) => state.dashboard.allApplicants,
	);

	const tabStatusData = useSelector(
		(state: RootState) => state.dashboard.submissionStatusesTotal,
	);

	const tableHeadersDefault = [
		{
			key: 'candidate',
			header: 'Candidate',
		},
		{
			key: 'status',
			header: 'Status',
		},
		{
			key: 'invited',
			header: 'Invited',
		},
	];

	const tableHeaderInProgress = [
		...tableHeadersDefault,
		{
			key: 'submissionDate',
			header: 'Submitted',
		},
	];

	const tableHeaderAll = [
		...tableHeadersDefault,
		{
			key: 'submissionDate',
			header: 'Submitted',
		},
		{
			key: 'clearedDate',
			header: 'Cleared',
		},
		{
			key: 'report',
			header: 'Report',
		},
	];

	const handleTabClick = (e: any) => {
		dispatch(setExternalTab(e.target.id));
		const seletedTab = tabs[e.target.id - 1].tabTitle;
		fireGaEvent('tab_click', 'Dashboard', `${seletedTab} tab clicked`);
	};

	// Filter the array of objects to only include items with a date that is within the last 2 days
	const newApplications = applicants.filter((obj) => {
		const currentDate = new Date();
		const parsedDate = new Date(obj.createdAt);
		const timeDifference = currentDate.getTime() - parsedDate.getTime();
		const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;

		return (
			timeDifference <= twoDaysInMilliseconds &&
			(obj.overallSubmissionStatus === 'not yet submitted' ||
				obj.overallSubmissionStatus === 'not yet created')
		);
	});

	const getColumns = (currentTab: string) => {
		switch (currentTab) {
			case '1':
				return tableHeaderAll;
			case '2':
				return tableHeadersDefault;
			case '3':
				return tableHeaderInProgress;
			case '4':
				return tableHeaderAll;
			case '5':
				return tableHeaderAll;
			case '6':
				return tableHeadersDefault;
			default:
				return null;
		}
	};

	const getData = () => {
		return applicants;
	};

	useEffect(() => {
		switch (currentTab) {
			case '1':
				dispatch(setSubmissionStatusTab('all'));
				break;
			case '2':
				dispatch(setSubmissionStatusTab('awaitingSubmission'));
				break;
			case '3':
				dispatch(setSubmissionStatusTab('inProgress'));
				break;
			case '4':
				dispatch(setSubmissionStatusTab('limited'));
				break;
			case '5':
				dispatch(setSubmissionStatusTab('full'));
				break;
			case '6':
				dispatch(setSubmissionStatusTab('cancelled'));
				break;
			default:
				break;
		}
	}, [currentTab, dispatch]);

	useEffect(() => {
		setTimeout(() => {
			dispatch(setPage(1));
		}, 50);
	}, [currentTab]);

	return (
		<div className='external-tabs-container'>
			<div className='tabs'>
				{tabs.map((tab, i) => {
					const isAwaitingSubmission =
						tab.tabTitle === 'Awaiting Submission';
					const hasNewApplications = newApplications.length !== 0;
					const isCurrentTab = currentTab === `${tab.id}`;
					const tabNumberStyle = {
						color: tab.textColor,
						fontSize: 27,
					};
					return (
						<button
							className='tab-button'
							key={i}
							id={tab.id}
							disabled={isCurrentTab}
							onClick={handleTabClick}
							style={{
								borderBottom: isCurrentTab
									? `4px solid ${
											i === 0
												? '#4A4A4A'
												: i === 1
													? '#9AA5B1'
													: i === 2
														? '#485BEB'
														: i === 3
															? '#F8C96A'
															: i === 4
																? '#1DC7B2'
																: '#EC8079'
										}`
									: 'none',
							}}
						>
							{isAwaitingSubmission && hasNewApplications ? (
								<>
									<p className='orange-bullet'>
										{tab.tabTitle}&nbsp;
									</p>
									<span
										className='tab-number'
										style={tabNumberStyle}
									>
										{(tabStatusData &&
											tabStatusData[i] &&
											tabStatusData[i].total) ||
											(tabData &&
												tabData[i] &&
												tabData[i].total)}
									</span>
								</>
							) : (
								<>
									{tab.tabTitle}
									<br />
									<span
										className='tab-number'
										style={tabNumberStyle}
									>
										{(tabStatusData &&
											tabStatusData[i] &&
											tabStatusData[i].total) ||
											(tabData &&
												tabData[i] &&
												tabData[i].total)}
									</span>
								</>
							)}
						</button>
					);
				})}
			</div>
			<div className='external-table-container'>
				{tabs.map((tab, i) => (
					<div key={i}>
						{currentTab === tab.id && (
							<ExternalDashboardTable
								columns={getColumns(currentTab)}
								data={getData()}
								type='Dashboard'
								role={role}
								onSelectedData={undefined}
							/>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default ExternalTabs;
