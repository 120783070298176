import React, { FC, useEffect, useState } from 'react';
import './activity-documents.styles.scss';
import ProfileSection from '../profile-section/profile-section.component';
import ProfileDocumentBucket from '../profile-document-bucket/profile-document-bucket.component';
import { IFileItem } from '../../../types/interfaces/document.interface';
import { WorkHistoryItem, WorkHistoryItemFile } from '../../../types/interface';
import { RootState } from '../../../types/root-state';
import { updateDocumentStatus } from '../../../api/api.docs';
import { useSelector } from 'react-redux';

type Props = {
	item: WorkHistoryItem;
};
enum tagName {
	documentStart = 'documentStart',
	documentEnd = 'documentEnd',
	documentReference = 'documentReference',
}
const ActivityDocuments: FC<Props> = ({ item }) => {
	const [columnDrop, setColumnDrop] = useState<string>('');
	const [dragOverColumn, setDragOverColumn] = useState<string>('');
	const [refresh, setRefresh] = useState<boolean>(false);
	const [fileToAdd, setFileToAdd] = useState<
		WorkHistoryItemFile | IFileItem
	>();
	const [documentStartFiles, setDocumentStartFiles] = useState<
		WorkHistoryItemFile[]
	>([]);
	const [documentEndFiles, setDocumentEndFiles] = useState<
		WorkHistoryItemFile[]
	>([]);
	const [documentReferenceFiles, setDocumentReferenceFiles] = useState<
		WorkHistoryItemFile[]
	>([]);

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const handleGetFiles = (file: IFileItem) => {
		const files = [...item.files];
		files.push(file);
		item.files = [...files];
		setFileToAdd(file);
	};

	useEffect(() => {
		if (item) {
			const docStartFiles = item.files.filter((file) => {
				if (file.tags && file.deletedAt === null) {
					return file.tags.includes(tagName.documentStart);
				} else if (file.tags === null) {
					return file;
				}
			});
			setDocumentStartFiles(docStartFiles);

			const docEndFiles = item.files.filter((file) => {
				if (file.tags && file.deletedAt === null) {
					return file.tags.includes(tagName.documentEnd);
				}
			});
			setDocumentEndFiles(docEndFiles);

			const docRefFiles = item.files.filter((file) => {
				if (file.tags && file.deletedAt === null) {
					return file.tags.includes(tagName.documentReference);
				}
			});
			setDocumentReferenceFiles(docRefFiles);
		}
	}, [refresh, JSON.stringify(fileToAdd)]);

	const emitFileToDelete = (file: IFileItem) => {
		const files = [...item.files];
		const updatedFiles = files.map((item) => {
			if (item.uuid === file.uuid) {
				return file;
			} else {
				return item;
			}
		});
		item.files = [...updatedFiles];
		setFileToAdd(file);
	};

	const emitDragEnded = (dragItem: any, dragOverItem: any) => {
		const files = [...item.files];
		const dragItemIndex = files.findIndex((item) => {
			return item === dragItem.current;
		});
		const dragOverIndex = files.findIndex((item) => {
			return item === dragOverItem.current;
		});

		const draggedItemContent = files.splice(dragItemIndex, 1)[0];
		// When moving files in different bucket the file will be at the top first.
		const colIndex = !dragItem.current.tags.includes(columnDrop)
			? 0
			: dragOverIndex;

		files.splice(colIndex, 0, draggedItemContent);

		if (
			draggedItemContent &&
			draggedItemContent.tags &&
			!draggedItemContent.tags.includes(columnDrop)
		) {
			draggedItemContent.tags = JSON.stringify([
				'unselected',
				columnDrop,
			]);
		}

		dragItem.current = null;
		dragOverItem.current = null;
		item.files = [...files];
		updateDocumentStatus(draggedItemContent, applicant, true);
		setRefresh(!refresh);
		setDragOverColumn('');
	};
	const dragOver = (e: any, column: string) => {
		setDragOverColumn(column);
		e.preventDefault();
	};
	return (
		<div className='activity-documents-section'>
			<ProfileSection title='Documents' hideSide={true}>
				<div
					onDragOver={(e) => dragOver(e, tagName.documentStart)}
					onDrop={() => setColumnDrop(tagName.documentStart)}
					className={`margin-bottom-24 margin-top-24' ${
						dragOverColumn === tagName.documentStart
							? 'draggable-area-active'
							: 'draggable-area'
					} `}
				>
					<p className='orka-profile-label'>
						Doc 1 (beginning of employment)
					</p>
					<ProfileDocumentBucket
						tag={tagName.documentStart}
						fileArray={documentStartFiles}
						isWork={true}
						workItemUuid={item.uuid}
						handleGetFiles={handleGetFiles}
						emitFileToDelete={emitFileToDelete}
						emitDragEnded={emitDragEnded}
						draggable={true}
					/>
				</div>

				<div
					onDragOver={(e) => dragOver(e, tagName.documentEnd)}
					onDrop={() => setColumnDrop(tagName.documentEnd)}
					className={`margin-bottom-24 margin-top-24' ${
						dragOverColumn === tagName.documentEnd
							? 'draggable-area-active'
							: 'draggable-area'
					} `}
				>
					<p className='orka-profile-label'>
						Doc 2 (end of employment)
					</p>
					<ProfileDocumentBucket
						tag={tagName.documentEnd}
						fileArray={documentEndFiles}
						isWork={true}
						workItemUuid={item.uuid}
						handleGetFiles={handleGetFiles}
						emitFileToDelete={emitFileToDelete}
						emitDragEnded={emitDragEnded}
						draggable={true}
					/>
				</div>

				<div
					onDragOver={(e) => dragOver(e, tagName.documentReference)}
					onDrop={() => setColumnDrop(tagName.documentReference)}
					className={`margin-bottom-24 margin-top-24' ${
						dragOverColumn === tagName.documentReference
							? 'draggable-area-active'
							: 'draggable-area'
					} `}
				>
					<p className='orka-profile-label'>Doc 3 (References)</p>
					<ProfileDocumentBucket
						tag={tagName.documentReference}
						fileArray={documentReferenceFiles}
						isWork={true}
						workItemUuid={item.uuid}
						handleGetFiles={handleGetFiles}
						emitFileToDelete={emitFileToDelete}
						emitDragEnded={emitDragEnded}
						draggable={true}
					/>
				</div>
			</ProfileSection>
		</div>
	);
};

export default ActivityDocuments;
