import React, { useEffect, useState, FC } from 'react';
import * as apiId from '../../../api/api.identity';
import { useSelector, useDispatch } from 'react-redux';
import './yoti-overwrite.styles.scss';
import { setAlert } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';

const YotiOverwrite: FC = () => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const yotiDetails = useSelector(
		(state: RootState) => state.profile.yotiDetails,
	);
	const isEditDisabled = useSelector(
		(state: RootState) => state.general.isEditDisabled,
	);
	const role = useSelector((state: RootState) => state.auth.role);
	const [showAlertConfirm, setShowAlertConfirm] = useState<boolean>(false);
	const [idStatus, setIdStatus] = useState<{ val: string; class: string }>({
		val: 'Processing',
		class: 'tag btn-pending',
	});

	useEffect(() => {
		switch (yotiDetails.sessionStatus) {
			case 'approved':
				setIdStatus({
					val: 'Passed',
					class: 'tag btn-passed',
				});
				break;
			case 'expired':
				setIdStatus({
					val: 'Expired',
					class: 'tag btn-failed',
				});
				break;
			case 'rejected':
				setIdStatus({
					val: 'Rejected',
					class: 'tag btn-failed',
				});
				break;
			case 'processing':
				setIdStatus({
					val: 'Processing',
					class: 'tag btn-pending',
				});
				break;
			case 'in-progress':
				setIdStatus({
					val: 'In-progress',
					class: 'tag btn-pending',
				});
				break;

			default:
				break;
		}
		if (yotiDetails.override) {
			setIdStatus({
				val: 'Passed',
				class: 'tag btn-passed',
			});
		}
	}, []);

	const submitStatus = async () => {
		apiId
			.yotiOverwrite(applicant.workerUuid)
			.then(() => {
				yotiDetails.override = true;
				setIdStatus({
					val: 'Passed',
					class: 'tag btn-passed',
				});
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully override Yoti',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Unable to override Yoti',
						isVisible: true,
					}),
				);
			});
	};

	const handleGetValFromAlert = (val: boolean) => {
		setShowAlertConfirm(false);
		if (val) {
			return submitStatus();
		}
	};

	return (
		<div className='manually-approve'>
			{showAlertConfirm && (
				<AlertConfirm
					message='Are you sure you want to override Yoti status ?'
					getVal={handleGetValFromAlert}
					confirm='Yes'
					reject='Cancel'
				/>
			)}
			<button
				className={idStatus.class}
				style={{
					cursor: isEditDisabled ? 'not-allowed' : 'pointer',
					opacity: isEditDisabled ? 0.5 : 1,
				}}
				onClick={() => {
					if (role === 'ambassador') {
						return;
					}
					if (
						yotiDetails.sessionStatus === 'approved' ||
						isEditDisabled
					) {
						return;
					}
					setShowAlertConfirm(true);
				}}
			>
				{idStatus.val}
			</button>
			<p>
				{role === 'ambassador' || isEditDisabled
					? 'Yoti status'
					: yotiDetails.override
						? 'Yoti is manually overridden'
						: yotiDetails.sessionStatus === 'approved'
							? 'Yoti status'
							: 'Click to manually override Yoti'}
			</p>
		</div>
	);
};

export default YotiOverwrite;
