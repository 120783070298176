import './status-badge.styles.scss';
import React, { FC } from 'react';
import { getStatusBackgroundColour, getStatusClassName, getStatusExplainerText, mapStatusTab, UserProfileInfoToolTip } from '../../../utils/externalUtilsFunction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { CheckType, StatusBadgeEnum } from '../../../types/enum';
import { IApplicant } from '../../../types/interface';
import InfoIconRtw from '../../../assets/icons/info-icon-rtw.svg';
import InfoIconBs7858 from '../../../assets/icons/info-icon-bs7858.svg';
import InfoIconRtwBs7858 from '../../../assets/icons/info-icon-rtwbs7858.svg';

interface StatusBadgeProps {
	statusType: StatusBadgeEnum;
};

const getInfoIconSrc = (status: string) => {
	switch (status) {
		case CheckType.RTW:
			return InfoIconRtw;
		case CheckType.BS7858:
			return InfoIconBs7858;
		case CheckType.RTWBS7858:
			return InfoIconRtwBs7858;
		default:
			return '';
	}
};

type BadgeStyles = {
	outerClassName: string;
	innerClassName: string;
	badgeText: string;
	badgeStyle: string;
	infoIconSrc?: string;
	badgeExplainer?: string;
};

const getStatusInfo = (applicant: IApplicant, statusType: StatusBadgeEnum): BadgeStyles => {
	if (statusType === StatusBadgeEnum.APPLICANT_STATUS) {
		return {
			outerClassName: 'external-user-profile-status-type',
			innerClassName: getStatusClassName(
				applicant.submissionStatusTab,
			),
			badgeText: mapStatusTab(applicant.submissionStatusTab),
			badgeStyle: getStatusBackgroundColour(
				applicant.submissionStatusTab,
			),
		}
	} 
	return {
		outerClassName: 'check-status-type external-user-profile-status-type',
		innerClassName: applicant.checkType,
		badgeText: mapStatusTab(applicant.checkType),
		badgeStyle: getStatusBackgroundColour(
			applicant.checkType,
		),
		infoIconSrc: getInfoIconSrc(applicant.checkType),
		badgeExplainer: getStatusExplainerText(applicant.checkType),
	}	
};

const renderBadge = (statusType: StatusBadgeEnum, statusStyles: BadgeStyles) => {
	return (
		<div
			className={statusStyles.outerClassName}
			style={{ backgroundColor: `${statusStyles.badgeStyle}` }}
		>
			<span className={statusStyles.innerClassName}>
				{statusStyles.badgeText}
			</span>
			{statusType === 'checkType' && (
				<img src={statusStyles.infoIconSrc} className="explainer-info-icon" />
			)}
		</div>
	)
};

const StatusBadge: FC<StatusBadgeProps> = ({ statusType }) => {
	const applicant = useSelector((state: RootState) => state.profile.applicant);
	const statusStyles = getStatusInfo(applicant, statusType);
	
	return (
			<>
				{statusType === StatusBadgeEnum.APPLICANT_STATUS ? (
					renderBadge(statusType, statusStyles)
				) : (
					<UserProfileInfoToolTip 
						title={getStatusExplainerText(applicant.checkType)}
						placement="bottom-start"
					>
						{renderBadge(statusType, statusStyles)}
					</UserProfileInfoToolTip>
				)}
			</>
		);
	};

export default StatusBadge;