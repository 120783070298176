import axios from 'axios';
import { axiosInstance } from './api.axiosInstance';
import { IFileItem } from '../types/interfaces/document.interface';
import { IApplicant, WorkHistoryItemFile } from '../types/interface';

export const getSignedUrl = (
	contentType: string,
	workerUuid: string,
	callType: string,
): Promise<any> => {
	const payload = {
		content_type: contentType,
	};

	const path =
		callType === 'workHistory' ? 'work-history/documents' : 'documents';
	const url = `admin/applicants/${workerUuid}/${path}`;

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const uploadToGoogle = (file: IFileItem): Promise<any> => {
	const url = file.writeUrl;
	const headers = {
		'content-type': file.type,
	};

	return axios.put(url, file, { headers }).catch((err) => {
		throw err;
	});
};

export const saveDocumentItem = (
	file: IFileItem,
	tags: string,
	applicant: IApplicant,
	isWork: boolean,
	workItemUuid: string | null,
): Promise<any> => {
	const url = isWork
		? `admin/applicants/${applicant.workerUuid}/work-history/documents/save`
		: `admin/applicants/${applicant.workerUuid}/documents/save`;

	const payload: any = {
		tags,
		workerUuid: isWork ? applicant.uuid : applicant.workerUuid,
		fileName: file.fileName,
		contentType: file.type,
		name: file.name,
	};

	if (workItemUuid) {
		payload.originalItemUuid = workItemUuid;
	}

	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const getDocuments = (uuid: string): Promise<any> => {
	const url = `admin/applicants/${uuid}/documents`;
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const deleteDocument = (
	file: IFileItem,
	applicant: IApplicant,
	deletedAt: Date | null | string,
): Promise<any> => {
	const url = `admin/applicants/${file.workerUuid}/documents/save`;

	const payload = {
		tags: JSON.stringify(file.tags),
		workerUuid: file.workerUuid,
		fileName: file.fileName,
		contentType: file.contentType,
		name: file.name,
		deletedAt,
		readUrl: file.readUrl,
		uuid: file.uuid,
	};

	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const deleteWorkDocument = (
	applicant: IApplicant,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	payload: any,
): Promise<any> => {
	const url = `admin/applicants/${applicant.workerUuid}/work-history/documents/save`;
	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const updateDocumentStatus = (
	file: IFileItem | WorkHistoryItemFile,
	applicant: IApplicant,
	isWork = false,
): Promise<any> => {
	const url = isWork
		? `admin/applicants/${applicant.workerUuid}/work-history/documents/save`
		: `admin/applicants/${applicant.workerUuid}/documents/save`;

	const payload = !isWork
		? {
				fileName: file.fileName,
				name: file.name,
				status: file.status,
				tags: JSON.stringify(file.tags),
				workerUuid: applicant.workerUuid,
				uuid: file.uuid,
			}
		: {
				fileUuid: file.uuid,
				status: file.status,
				tags: file.tags,
			};

	return axiosInstance()
		.patch(url, payload)
		.catch((err) => {
			throw err;
		});
};
