import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './profile-document-details.styles.scss';
import {
	IDropdownStandard,
	YotiDocumentDetails,
} from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { IStaffMember } from '../../../types/interfaces/staff.interface';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import checkboxSelected from '../../../assets/icons/checkbox-tick.svg';
import * as apiId from '../../../api/api.identity';
import { ICandidateRTWDetails } from '../../../types/interfaces/identity.interface';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import GuidanceToggle from '../../general/guidance-toggle/guidance-toggle.component';
import { CheckType } from '../../../types/enum';
import { guidanceToggleVisible } from '../../../utils/externalUtilsFunction';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';
import ProfileDisplayItem from '../profile-display-item/profile-display-item.component';

type Props = {
	yotiDocumentDetails: YotiDocumentDetails;
	setRefetch?: Dispatch<SetStateAction<boolean>>;
	refetch?: boolean;
	isExternal?: boolean;
	checkType?: CheckType | null;
};

const ProfileDocumentDetails: FC<Props> = ({
	yotiDocumentDetails,
	setRefetch,
	refetch,
	isExternal,
	checkType,
}) => {
	const dispatch = useDispatch();
	const yotiDetails = useSelector(
		(state: RootState) => state.profile.yotiDetails,
	);
	const isEditDisabled = useSelector(
		(state: RootState) => state.general.isEditDisabled,
	);
	const [staff, setStaff] = useState<IDropdownStandard[]>([]);
	const [defaultStaff, setDefaultStaff] = useState<IDropdownStandard>();
	const allStaff = useSelector((state: RootState) => state.auth.staff);
	const [checked, setChecked] = useState<boolean>(
		yotiDocumentDetails.identityVerified,
	);
	const [showAlertConfirm, setShowAlertConfirm] = useState<boolean>(false);
	const [checkedByName, setCheckedByName] = useState<string>('');
	const defaultItem: IDropdownStandard = {
		id: 0,
		value: 'Checked by',
		dbValue: isEditDisabled ? 'None yet' : 'Checked by',
		class: 'dropdown-dark',
	};
	useEffect(() => {
		defaultSelectedDropDown();
		const dropdownData = allStaff
			.filter(
				(element) =>
					element.role === 'Controller' ||
					element.role === 'Screener',
			)
			.map((staffMember: IStaffMember, index: number) => {
				return mapMember(staffMember, index);
			});

		setStaff([defaultItem, ...dropdownData]);
	}, [allStaff.length]);

	const mapMember = (staffMember: IStaffMember, index: number) => {
		return {
			id: index + 1,
			value: staffMember.name + ' ' + staffMember.surname,
			dbValue: staffMember.uuid,
			class: 'dropdown-dark',
			staffMember: staffMember,
		};
	};

	const defaultSelectedDropDown = () => {
		if (yotiDocumentDetails.evidenceCheckedBy) {
			const found = allStaff.filter(
				(element) =>
					element.uuid === yotiDocumentDetails.evidenceCheckedBy,
			);
			if (found && found.length > 0) {
				setCheckedByName(
					utils.getFullName(null, found[0].name, found[0].surname),
				);
			} else {
				setDefaultStaff(defaultItem);
			}
		} else {
			setDefaultStaff(defaultItem);
		}
	};

	const getDropdownValue = async (val: IDropdownStandard) => {
		if (val.id > 0) {
			const res: boolean = await updateYotiDetails({
				evidenceCheckedBy: val.dbValue,
			});
			if (res && setRefetch) {
				setRefetch(!refetch);
			}
		}
	};

	const markCheck = () => {
		if (checked) {
			return;
		}
		setShowAlertConfirm(true);
	};

	const handleAlert = async (val: boolean) => {
		setShowAlertConfirm(false);
		if (val) {
			const res: boolean = await updateYotiDetails({
				identityVerified: !checked,
			});
			if (res) {
				setChecked(!checked);
				yotiDocumentDetails.identityVerified = true;
			}
		}
	};

	const getEvidenceCheckedByName = () => {
		const verifier = allStaff.find(
			(staff) => staff.uuid === yotiDocumentDetails.evidenceCheckedBy,
		);

		if (verifier) {
			return `${verifier.name} ${verifier.surname}`;
		}

		return 'Not yet verified';
	};

	const updateYotiDetails = (
		payload: Partial<ICandidateRTWDetails>,
	): Promise<boolean> => {
		return apiId
			.yotiUpdateDetails(yotiDetails.sessionUuId, payload)
			.then((res) => {
				if (res.status === 404) {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to update Passport details',
							isVisible: true,
						}),
					);
					return false;
				}
				return true;
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				return false;
			});
	};

	const renderExternalDetailsSection = (
		yotiDocumentDetails: YotiDocumentDetails,
	) => {
		return (
			<section className='sub-section-container'>
				<div className='sub-section-identity-container'>
					<div className='sub-section-info-table'>
						<div>
							<p className='info-table-title'>Forename:</p>
							<p>{yotiDocumentDetails.forename}</p>
						</div>
						<div>
							<p className='info-table-title'>Surname:</p>
							<p>{yotiDocumentDetails.surname}</p>
						</div>
						<div>
							<p className='info-table-title'>
								Middle Name: (if applicable)
							</p>
							<p>
								{yotiDocumentDetails.middleName
									? yotiDocumentDetails.middleName
									: '-'}
							</p>
						</div>
						<div>
							<p className='info-table-title'>Date of Birth:</p>
							<p>
								{yotiDocumentDetails.dateOfBirth
									? utils.formatDateForUser(
											yotiDocumentDetails.dateOfBirth,
										)
									: 'N/A'}
							</p>
						</div>
					</div>
					{checkType && guidanceToggleVisible(checkType) && (
						<GuidanceToggle guidanceType='rtw-yoti-details' />
					)}
				</div>
			</section>
		);
	};

	const renderInternalDetailsSection = (
		yotiDocumentDetails: YotiDocumentDetails,
	) => {
		return (
			<div className='profile-document-details'>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>Forename</p>
					<p className='orka-semi-p'>
						{yotiDocumentDetails.forename}
					</p>
				</div>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>
						Middle name(s)
					</p>
					<p className='orka-semi-p'>
						{yotiDocumentDetails.middleName
							? yotiDocumentDetails.middleName
							: '-'}
					</p>
				</div>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>Surname</p>
					<p className='orka-semi-p'>{yotiDocumentDetails.surname}</p>
				</div>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>
						Date of birth
					</p>
					<p className='orka-semi-p'>
						{yotiDocumentDetails.dateOfBirth
							? utils.formatDateForUser(
									yotiDocumentDetails.dateOfBirth,
								)
							: '-/-/-'}
					</p>
				</div>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>Nationality</p>
					<p className='orka-semi-p'>
						{yotiDocumentDetails.nationality
							? yotiDocumentDetails.nationality
							: '-'}
					</p>
				</div>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>
						Expiry date of document
					</p>
					<p className='orka-semi-p'>
						{yotiDocumentDetails.expiryDate
							? utils.formatDateForUser(
									yotiDocumentDetails.expiryDate,
								)
							: '-/-/-'}
					</p>
				</div>

				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>
						Identity Verified
					</p>
					<div className='checkbox-area'>
						<img
							className={checked ? 'checkbox-disabled' : ''}
							onClick={() => {
								if (!isEditDisabled) {
									markCheck();
								}
							}}
							src={checked ? checkboxSelected : checkboxWhite}
							alt='checkbox'
							style={{
								cursor: isEditDisabled
									? 'not-allowed'
									: 'pointer',
								opacity: isEditDisabled ? 0.5 : 1,
							}}
						/>
						<p className='orka-semi-p margin-left-8'>Yes</p>
					</div>
				</div>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>
						Evidence checked by
					</p>
					<div className='margin-top-8'>
						{isEditDisabled ? (
							<ProfileDisplayItem
								label=''
								value={defaultStaff ? defaultStaff.dbValue : null}
							/>
						) : (
							defaultStaff &&
							staff && (
								<Dropdown
									type='staff'
									dropdownData={staff}
									defaultData={defaultStaff}
									getDropdownVal={getDropdownValue}
									width='180'
									dropDownWidth='290'
									mobileResponsive={false}
									position='bottom-left'
									height={24}
								/>
							)
						)}
					</div>
					<p className='orka-semi-p'>
						{yotiDocumentDetails.evidenceCheckedBy
							? getEvidenceCheckedByName()
							: null}
					</p>
				</div>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>
						Date of check
					</p>
					<p className='orka-semi-p'>
						{yotiDocumentDetails.dateOfCheck
							? utils.formatDateForUser(
									yotiDocumentDetails.dateOfCheck,
								)
							: '-/-/-'}
					</p>
				</div>
				<div className='margin-bottom-24'>
					<p className='orka-profile-label demi-bold'>
						GPG45 Profile (level of confidence)
					</p>
					<p className='orka-semi-p'>
						{utils.capitalizeFirstLetter(
							yotiDocumentDetails.levelOfConfidence,
						)}
					</p>
				</div>
			</div>
		);
	};

	return (
		<>
			{isExternal
				? renderExternalDetailsSection(yotiDocumentDetails)
				: renderInternalDetailsSection(yotiDocumentDetails)}
			{showAlertConfirm && (
				<AlertConfirm
					message={`Are you sure you want to check Identity Verified for this user?`}
					getVal={handleAlert}
					confirm='Yes'
					reject='Cancel'
				/>
			)}
		</>
	);
};

export default ProfileDocumentDetails;
