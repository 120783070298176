import './external-user-profile-sections.styles.scss';
import React, { FC, useState } from 'react';
import { fireGaEvent } from '../../../utils/ga-events';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { formatDateToText } from '../../../utils/externalUtilsFunction';
import { User } from '../../../types/interface';

import * as utils from '../../../utils/utilsFunctions';
import ExternalUserProfileStatus from './external-user-profile-status.component';
import ExternalUserProfileRisks from '../external-user-profile-risks/external-user-profile-risks.component';

import chevronDown from '../../../assets/icons/chevron-down.svg';
import flagDarkGrey from '../../../assets/icons/flag-dark-grey.svg';
import flagRed from '../../../assets/icons/flag-red.svg';
import educationIcon from '../../../assets/icons/external-portal/user-profile/education.svg';
import briefcaseIcon from '../../../assets/icons/external-portal/user-profile/briefcase.svg';
import downloadFileIcon from '../../../assets/icons/external-portal/user-profile/download-file.svg';
import careerBreakIcon from '../../../assets/icons/external-portal/user-profile/career-break.svg';
import volunteeringIcon from '../../../assets/icons/external-portal/user-profile/volunteering.svg';
import selfEmployedIcon from '../../../assets/icons/external-portal/user-profile/self-employed.svg';

type Props = {
	loggedInUser: User;
};

const ExternalUserProfileActivity: FC<Props> = ({ loggedInUser }) => {
	const workHistory = useSelector(
		(state: RootState) => state.profile.workHistory,
	);

	const activityStageData = useSelector(
		(state: RootState) => state.profile.stageData[1],
	);

	const [openSections, setOpenSections] = useState<boolean[]>(
		Array(workHistory?.length || 0).fill(false),
	);

	const getWorkHistoryIcon = (type: string) => {
		switch (type) {
			case 'studying':
				return educationIcon;
			case 'employed':
				return briefcaseIcon;
			case 'volunteering':
				return volunteeringIcon;
			case 'self-employed':
				return selfEmployedIcon;
			case 'travelling':
			case 'job-seeking':
			case 'medical-reasons':
			case 'carer':
			case 'parental-cover':
			case 'self-study':
			case 'another-reason':
				return careerBreakIcon;
			default:
				return briefcaseIcon;
		}
	};

	const getTagTitle = (tag: string): string => {
		switch (tag) {
			case 'documentStart':
				return 'Beginning of Employment';
			case 'documentEnd':
				return 'End of Employment';
			case 'documentReference':
				return 'References';
			default:
				return 'Other';
		}
	};

	const getSubSectionStatusColour = (subSectionStatus: string) => {
		switch (subSectionStatus) {
			case 'All Evidence Received':
			case 'Documents Received':
				return '#485BEB';
			case 'RequestSent':
			case 'In Dispute':
			case 'Unable To Verify':
			case 'In Discussion':
				return '#F8C96A';
			default:
				return '#F8C96A';
		}
	};

	const renderWorkHistorySubSections = () => {
		return workHistory
			.filter((history) => history.type !== 'gap')
			.map((history, index) => (
				<section
					key={index}
					className='external-user-profile-sub-section-container'
				>
					<div
						className='external-user-profile-sub-section-title-container'
						onClick={() => {
							const newOpenSections = [...openSections];
							newOpenSections[index] = !newOpenSections[index];
							setOpenSections(newOpenSections);
							fireGaEvent(
								'toggle_click',
								'Candidate Profile',
								`Activity History  - ${history.type} clicked`,
							);
						}}
					>
						<img
							className='external-user-profile-sub-section-icon'
							src={getWorkHistoryIcon(history.type)}
							alt='activity-icon'
						></img>
						<p className='external-user-profile-sub-section-title'>
							{history.type === 'another-reason'
								? 'Career Break'
								: utils.capitalizeFirstLetterOfEach(
										utils.removeHyphen(history.type),
									)}
						</p>
						<p className='external-user-profile-sub-section-dates'>
							{utils.formatDateForUser(history.startAt)} -{' '}
							{history.endAt
								? new Date(history.endAt).toDateString() ===
									new Date().toDateString()
									? 'Present'
									: utils.formatDateForUser(history.endAt)
								: 'Present'}
						</p>
						<img
							className='sub-chevron'
							src={chevronDown}
							alt='arrow'
							style={{
								transform: openSections[index]
									? 'scaleY(-1)'
									: 'none',
							}}
						></img>
					</div>
					<div className='external-user-profile-sub-section-subtitle-container'>
						<p className='external-user-profile-sub-section-subtitle'>
							{history.name ? history.name : 'N/A'}
						</p>
						<div
							className='external-user-profile-sub-section-status-text'
							style={{
								color: getSubSectionStatusColour(
									utils.capitalizeFirstLetterOfEach(
										history?.reference?.status,
									),
								),
							}}
						>
							{utils.capitalizeFirstLetterOfEach(
								history?.reference?.status || 'Pending',
							)}
						</div>
					</div>
					<div
						className='external-user-profile-sub-section-body'
						style={{
							display: openSections[index] ? 'block' : 'none',
						}}
					>
						<div className='external-user-profile-sub-section-job-title'>
							{history.type === 'another-reason' ? (
								<>
									<p className='external-user-profile-sub-section-career-break'>
										Career Break Reason
									</p>{' '}
									{history.careerBreakReason}
								</>
							) : (
								<>
									{history.jobTitle ? (
										<>
											{history.jobTitle} -{' '}
											{history.isPartTime
												? 'Part Time'
												: 'Full Time'}
										</>
									) : (
										<>
											{history.isPartTime
												? 'Part Time'
												: 'Full Time'}
										</>
									)}
								</>
							)}
						</div>
						{history.reasonForLeaving && (
							<>
								<p className='external-user-profile-sub-section-leaving'>
									Reason for Leaving
								</p>
								{history.reasonForLeaving === 'Other' ? (
									<p className='external-user-profile-sub-section-leaving-text'>
										{history.reasonForLeaving} -{' '}
										{history.reasonForLeavingText}
									</p>
								) : (
									<p className='external-user-profile-sub-section-leaving-text'>
										{history.reasonForLeaving}
									</p>
								)}
							</>
						)}
						{(history.email || history.contactNumber) && (
							<>
								<p className='external-user-profile-sub-section-reference-title'>
									Reference Contact Details
								</p>
								<div className='external-user-profile-sub-section-reference'>
									<p>{history.contactNumber}</p>
									<a
										href={`mailto:${history.email}`}
										target='_blank'
										rel='noopener noreferrer'
										className='external-user-profile-sub-section-email'
									>
										{history.email}
									</a>
								</div>
							</>
						)}
						{history.files && history.files.length > 0 ? (
							<div className='external-user-profile-sub-section-file-container'>
								<p className='external-user-profile-sub-section-file-title'>
									Files
								</p>
								{[
									'documentStart',
									'documentEnd',
									'documentReference',
								].map((tag) => (
									<div key={tag}>
										{history.files.filter(
											(file) =>
												file.tags?.includes(tag) &&
												!file.deletedAt &&
												file.status !== 'rejected',
										).length > 0 ? (
											<div
												style={{
													marginTop: 10,
													marginBottom: 20,
												}}
											>
												<p className='external-user-profile-sub-section-text-light'>
													{getTagTitle(tag)}
												</p>
												{history.files
													.filter(
														(file) =>
															file.tags?.includes(
																tag,
															) &&
															!file.deletedAt &&
															file.status !==
																'rejected',
													)
													.map((file, index) => (
														<div
															key={index}
															className='external-user-profile-sub-section-file-content'
														>
															<a
																href={
																	file.fileUrl
																}
																target='_blank'
																rel='noreferrer'
																className='external-user-profile-sub-section-file-link'
															>
																<img
																	className='external-user-profile-sub-section-file-icon'
																	src={
																		downloadFileIcon
																	}
																	alt='file-icon'
																/>
																<span className='external-user-profile-sub-section-file-number'>
																	File{' '}
																	{index + 1}
																</span>
															</a>
															<span
																className='external-user-profile-sub-section-file-status'
																style={{
																	color:
																		file.status ===
																		'accepted'
																			? '#485BEB'
																			: file.status ===
																				  'pending'
																				? '#F8C96A'
																				: '#EC8079',
																}}
															>
																{utils.capitalizeFirstLetter(
																	file.status,
																)}
															</span>
														</div>
													))}
											</div>
										) : null}
									</div>
								))}
							</div>
						) : (
							<div className='external-user-profile-sub-section-file-container'>
								<p className='external-user-profile-sub-section-file-title'>
									Files
								</p>
								<p className='external-user-profile-sub-section-file-text'>
									No Files Available
								</p>
							</div>
						)}
						<div className='last-verified'>
							Last Verified on{' '}
							{formatDateToText(
								new Date(
									history.reference.statusUpdatedAt
										? history.reference.statusUpdatedAt
										: history.verifiedAt,
								)
									.toISOString()
									.substring(0, 10),
							)}
						</div>
					</div>
				</section>
			));
	};

	const getSectionStatus = () => {
		if (!workHistory || workHistory.length === 0) {
			return '';
		}

		const statuses = workHistory.map(
			(history) => history?.reference?.status,
		);

		const isVerified = statuses.every(
			(status) =>
				status === 'All Evidence Received' ||
				status === 'Documents Received',
		);

		const isPartiallyVerified = statuses.some(
			(status) =>
				status === 'All Evidence Received' ||
				status === 'Documents Received',
		);

		const isStageCompleted = activityStageData?.stageComplete;

		if (isVerified || isStageCompleted) {
			return 'verified';
		} else if (isPartiallyVerified) {
			return 'partially_verified';
		} else {
			return '';
		}
	};

	const getLastVerifiedDate = (): string => {
		const verifiedDates: Date[] = workHistory
			.filter(
				(history) =>
					history.reference?.statusUpdatedAt || history.verifiedAt,
			)
			.map(
				(history) =>
					new Date(
						history.reference?.statusUpdatedAt ??
							history.verifiedAt,
					),
			);

		const latestDate: Date | undefined = verifiedDates.sort(
			(a, b) => b.getTime() - a.getTime(),
		)[0];

		return latestDate
			? formatDateToText(latestDate.toISOString().substring(0, 10))
			: '';
	};

	return (
		<section className='view-container'>
			<div className='identity-container'>
				<p className='identity-header-title'>Activity History</p>
				<div className='identity-header-right'>
					<ExternalUserProfileStatus
						sectionStatus={getSectionStatus()}
					/>
					<img
						className='risk-flag'
						src={
							activityStageData &&
							activityStageData.risks.length > 0
								? flagRed
								: flagDarkGrey
						}
						alt='flag'
					/>
				</div>
			</div>
			<div className='user-profile-section-divider-line'></div>
			<div className='user-profile-section-main'>
				{workHistory && renderWorkHistorySubSections()}
				{activityStageData?.risks.length > 0 && (
					<ExternalUserProfileRisks
						stageData={activityStageData}
						loggedInUser={loggedInUser}
					/>
				)}
			</div>
			{['partially_verified', 'verified'].includes(getSectionStatus()) ? (
				<div className='last-verified'>
					Last Verified on {getLastVerifiedDate()}
				</div>
			) : null}
		</section>
	);
};

export default ExternalUserProfileActivity;
